import { Routes } from '@angular/router'
import { ApplicationRoutesEnum } from './shared/models/enums/application-routes.enum'
import { AuthenticationService } from './shared/services/authentication.service'
import { CanActivateHasRoles, CanActivateNoRoles } from './shared/services/can-activate.guard.service'

export const routes: Routes = [
   { path: '', redirectTo: ApplicationRoutesEnum.MAIN_PAGE, pathMatch: 'full' },
   {
      path: ApplicationRoutesEnum.MAIN_PAGE,
      canActivate: [AuthenticationService, CanActivateHasRoles],
      loadChildren: () => import('./core/components/pages/private/main-page/main-page.routes').then((m) => m.MAIN_PAGE_ROUTES),
   },
   {
      path: 'access-denied',
      canActivate: [AuthenticationService, CanActivateNoRoles],
      loadComponent: () => import('./core/components/pages/public/access-denied-page/access-denied-page.component'),
   },
]
