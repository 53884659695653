import { inject } from '@angular/core'
import { ActivatedRouteSnapshot, CanActivateFn, Router, RouterStateSnapshot, UrlTree } from '@angular/router'
import { Observable } from 'rxjs'
import { AuthenticationService } from './authentication.service'

export const CanActivateNoRoles: CanActivateFn = (
   route: ActivatedRouteSnapshot,
   state: RouterStateSnapshot
): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree => {
   return !inject(AuthenticationService).hasLicence() ? true : inject(Router).navigate(['/'])
}

export const CanActivateHasRoles: CanActivateFn = (
   route: ActivatedRouteSnapshot,
   state: RouterStateSnapshot
): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree => {
   return inject(AuthenticationService).hasLicence() ? true : inject(Router).navigate(['/access-denied'])
}
