import { APP_INITIALIZER, ApplicationConfig, importProvidersFrom, provideZoneChangeDetection } from '@angular/core'
import { provideRouter, withHashLocation } from '@angular/router'

import { DatePipe } from '@angular/common'
import { HTTP_INTERCEPTORS, HttpBackend, HttpClient, provideHttpClient, withInterceptorsFromDi } from '@angular/common/http'
import { provideAnimations } from '@angular/platform-browser/animations'
import { EffectsModule } from '@ngrx/effects'
import { StoreModule } from '@ngrx/store'
import { StoreDevtoolsModule } from '@ngrx/store-devtools'
import { TranslateLoader, TranslateModule } from '@ngx-translate/core'
import { TranslateHttpLoader } from '@ngx-translate/http-loader'
import { SimpleNotificationsModule } from 'angular2-notifications'
import { KeycloakBearerInterceptor, KeycloakService } from 'keycloak-angular'
import { InlineSVGModule } from 'ng-inline-svg-2'
import { DialogService, DynamicDialogRef } from 'primeng/dynamicdialog'
import { routes } from './app.routes'
import { SpinnerService } from './shared/components/spinner/spinner.service'
import { FLinkingToolEffects } from './shared/store/feature-linking-tool/f-linking-tool.effects'
import { featureLinkingToolReducer } from './shared/store/feature-linking-tool/f-linking-tool.reducer'
import { StageEffects } from './shared/store/stage/stage.effects'
import { stageReducer } from './shared/store/stage/stage.reducer'
import { firstValueFrom } from 'rxjs'
import { ConfigService } from './shared/services/config.service'
import { ConfirmationService } from 'primeng/api'

export const appConfig: ApplicationConfig = {
   providers: [
      provideZoneChangeDetection({ eventCoalescing: true }),
      provideRouter(routes, withHashLocation()),
      provideAnimations(),
      provideHttpClient(withInterceptorsFromDi()),
      DatePipe,
      DialogService,
      SpinnerService,
      {
         provide: DynamicDialogRef,
         useValue: [],
      },
      TranslateModule.forRoot({
         defaultLanguage: 'en-US',
         loader: {
            provide: TranslateLoader,
            useFactory: HttpLoaderFactory,
            deps: [HttpBackend],
         },
      }).providers!,
      {
         provide: APP_INITIALIZER,
         multi: true,
         deps: [ConfigService],
         useFactory: (configService: ConfigService) => {
            return () => configService.initRuntimeConfig()
         },
      },
      {
         provide: APP_INITIALIZER,
         useFactory: initializeKeycloak,
         multi: true,
         deps: [KeycloakService, HttpClient],
      },
      KeycloakService,
      ConfirmationService,
      { provide: HTTP_INTERCEPTORS, useClass: KeycloakBearerInterceptor, multi: true },
      importProvidersFrom(
         InlineSVGModule.forRoot(),
         StoreModule.forRoot({ stage: stageReducer, fLinkingToolState: featureLinkingToolReducer }),
         EffectsModule.forRoot([StageEffects, FLinkingToolEffects]),
         StoreDevtoolsModule.instrument({
            maxAge: 25,
         }),
         SimpleNotificationsModule.forRoot({
            timeOut: 5000,
            showProgressBar: true,
            pauseOnHover: true,
            clickToClose: true,
            position: ['bottom', 'right'],
            lastOnBottom: true,
            maxStack: 3,
            maxLength: 500,
            theClass: 'app-notify',
            preventDuplicates: true,
         })
      ),
   ],
}

// required for AoT
export function HttpLoaderFactory(handler: HttpBackend) {
   return new TranslateHttpLoader(new HttpClient(handler))
}

function initializeKeycloak(keycloak: KeycloakService, httpClient: HttpClient) {
   return () => {
      return firstValueFrom(httpClient.get<KeycloakConfig>('assets/configs/properties.json')).then((kc) => {
         return keycloak.init({
            config: {
               url: kc.keycloak.authUrl,
               realm: kc.keycloak.realm,
               clientId: kc.keycloak.clientId,
            },
            initOptions: { enableLogging: true, checkLoginIframe: false, flow: 'standard', onLoad: 'check-sso' },
            enableBearerInterceptor: true,
            bearerPrefix: 'Bearer ',
         })
      })
   }
}

interface KeycloakConfig {
   keycloak: {
      authUrl: string
      realm: string
      clientId: string
   }
}
